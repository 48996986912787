import { SerializeFrom } from "@remix-run/cloudflare";
import { useRouteLoaderData } from "@remix-run/react";
import { type loader as rootLoader } from "~/root";

// Remix theme utils below
export function useRequestInfo() {
  const data = useRouteLoaderData("root") as SerializeFrom<typeof rootLoader>;
  return data.requestInfo;
}

export function useHints() {
  const requestInfo = useRequestInfo();
  return requestInfo.hints;
}

export enum Theme {
  DARK = "dark",
  LIGHT = "light",
  SYSTEM = "system",
}

export const themes: Array<Theme> = Object.values(Theme);

export function isTheme(value: unknown): value is Theme {
  return typeof value === "string" && themes.includes(value as Theme);
}

export function themeName(theme: string) {
  switch (theme) {
    case "system":
      return "System";
    case "light":
      return "Hell";
    case "dark":
      return "Dunkel";
  }
}
